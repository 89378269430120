import axios from 'axios'

const fetchTasks = async (flowId) => {
  if (!flowId) return []

  const { data } = await axios.get(
    `/api/flow/tasks?flowId=${flowId}&sort=createdAt&dir=-1&stripTaskPatches=true`
  )
  return data
}

const saveTask = async (taskId, data, callback, errorCallback) => {
  const url = `/api/flow/tasks/${taskId}`

  await axios
    .put(url, data)
    .then((response) => {
      setTimeout(() => {
        callback(response.data)
      }, 2000)
    })
    .catch((error) => {
      console.error(error)
      if (errorCallback) {
        errorCallback(error)
      }
    })
}

const completeTask = async (taskId, callback, errorCallback) => {
  const url = `/api/flow/tasks/${taskId}/complete`

  await axios
    .post(url)
    .then((response) => {
      setTimeout(() => {
        callback(response.data)
      }, 2000)
    })
    .catch((error) => {
      console.error(error)
      if (errorCallback) {
        errorCallback(error)
      }
    })
}

const completeAsRemovedTask = async (taskId, callback, errorCallback) => {
  const url = `/api/flow/tasks/${taskId}/complete`

  await axios
    .post(url, {
      removed: true
    })
    .then((response) => {
      setTimeout(() => {
        callback(response.data)
      }, 2000)
    })
    .catch((error) => {
      console.error(error)
      if (errorCallback) {
        errorCallback(error)
      }
    })
}

const completeTaskWithData = async (taskId, data, callback, errorCallback) => {
  const url = `/api/flow/tasks/${taskId}/complete`

  await axios
    .post(url, data)
    .then((response) => {
      setTimeout(() => {
        callback(response.data)
      }, 2000)
    })
    .catch((error) => {
      console.error(error)
      if (errorCallback) {
        errorCallback(error)
      }
    })
}

const triggerTask = async (taskId, requestBody) => {
  const url = `/api/flow/tasks/${taskId}/trigger`
  await axios.post(url, requestBody)
}

const triggerMessage = async (flowId, taskType, requestBody) => {
  const { data: tasks } = await axios.get(
    `/api/flow/tasks?flowId=${flowId}&taskType=${taskType}&status=pending&limit=1&sort=createdAt&dir=-1&stripTaskPatches=true`
  )

  if (!tasks || tasks.length === 0) {
    throw new Error(`Could not find any tasks for flow: ${flowId}`)
  }

  const [task] = tasks
  return triggerTask(task.taskId, requestBody)
}

export default {
  fetchTasks,
  saveTask,
  completeTask,
  completeAsRemovedTask,
  completeTaskWithData,
  triggerMessage
}
