export const queueDefinition: any = {
  'my-cases': {
    name: 'my-cases',
    queueType: 'assignee',
    queueFilter: '?status=!completed&status=!archived',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 30
  },
  'document-control-completed-unread-chat': {
    name: 'document-control-completed-unread-chat',
    queueFilter: '?flowDefinitionId=document-control&data.storedFiles=',
    queueSort: 'sort=createdAt&dir=-1',
    queueType: 'chat',
    limit: 30,
    pollingEnabled: false
  },
  Incoming: {
    name: 'Incoming',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control&data.externalRequiredDocumentsOk=false',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 30,
    limitIncrease: 20
  },
  Inbox: {
    name: 'Inbox',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control&data.externalRequiredDocumentsOk=true&data.allDocumentsAreOk=false&assignee.id=!',
    queueSort: 'sort=data.inboxDate&dir=-1',
    limit: 60,
    limitIncrease: 20
  },
  Control: {
    name: 'Control',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control&data.externalRequiredDocumentsOk=true&data.allDocumentsAreOk=false&assignee.id=',
    queueSort: 'sort=data.inboxDate&dir=-1',
    limit: 30,
    limitIncrease: 20
  },
  Audit: {
    name: 'Audit',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control',
    taskFilter:
      '/?taskType=audit-documents&status=pending&sort=createdAt&dir=-1',
    queueType: 'task',
    limit: 30,
    limitIncrease: 20
  },
  'Select post control': {
    name: 'Select post control',
    queueFilter:
      '?view=queueView&status=active&flowDefinitionId=document-control&data.businessStatus=select-post-control',
    queueSort: 'sort=data.selectPostControlDate&dir=1',
    limit: 20
  },
  'Post control': {
    name: 'Post control',
    queueFilter:
      '?view=queueView&status=active&flowDefinitionId=document-control&data.businessStatus=post-control',
    queueSort: 'sort=data.postControlDate&dir=1',
    limit: 20
  },
  Archiving: {
    name: 'Archiving',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control&data.storedFiles=',
    queueSort: 'sort=updatedAt&dir=1',
    limit: 20,
    limitIncrease: 20
  },
  Failed: {
    name: 'Failed',
    queueFilter:
      '?view=queueView&status=!archived&flowDefinitionId=document-control',
    taskFilter: '/?status=failed&processDefinitionId=document-control',
    countTaskFilter: '/?status=failed',
    queueType: 'task'
  },
  'document-control-unread-chat': {
    name: 'document-control-unread-chat',
    queueFilter:
      '?view=queueView&flowDefinitionId=document-control&status=active&data.storedFiles=!',
    queueSort: 'sort=createdAt&dir=-1',
    queueType: 'chat',
    limit: 30,
    pollingEnabled: false
  },
  'Credit Inbox': {
    name: 'Credit Inbox',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=credit-check&assignee.id=!&data.caseDetails.contractStatus=!Approved',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 30,
    limitIncrease: 20
  },
  'In progress': {
    name: 'In progress',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=credit-check&assignee.id=&data.caseDetails.contractStatus=!Approved',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 30,
    limitIncrease: 20
  },
  Approved: {
    name: 'Approved',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=credit-check&data.caseDetails.contractStatus=Approved',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 30
  },
  'Failed credit': {
    name: 'Failed credit',
    queueFilter:
      '?view=queueView&status=!archived&flowDefinitionId=credit-check&hasErrors=true',
    queueSort: 'sort=createdAt&dir=-1',
    pollingEnabled: false
  },
  'credit-unread-chat': {
    name: 'credit-unread-chat',
    queueFilter: '?view=queueView&flowDefinitionId=credit-check&status=active',
    queueSort: 'sort=createdAt&dir=-1',
    queueType: 'chat',
    pollingEnabled: false,
    limit: 30
  },
  'credit-not-approved-uploads': {
    name: 'credit-not-approved-uploads',
    queueFilter: '?flowDefinitionId=credit-check&status=active',
    taskFilter:
      '/?taskType=cc-approve-document&status=pending&sort=createdAt&dir=-1',
    queueType: 'task'
  },
  'Payout control': {
    name: 'Payout control',
    queueFilter:
      '?view=queueView&status=!archived&status=!completed&flowDefinitionId=payout-check',
    queueType: 'task',
    taskFilter:
      '/?taskType=payout-control&status=pending&sort=createdAt&dir=-1',
    queueSort: 'sort=createdAt&dir=-1'
  },
  'Payout done': {
    name: 'Payout done',
    queueFilter:
      '?view=queueView&status=!archived&status=!completed&flowDefinitionId=payout-check',
    queueType: 'task',
    taskFilter: '/?taskType=payout-done&status=pending&sort=createdAt&dir=-1',
    queueSort: 'sort=createdAt&dir=-1'
  },
  'cd-assessment-queue-not-car': {
    name: 'cd-assessment-queue-not-car',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=~customer-declaration&data.businessStatus=assessment&data.concept=!car&assignee.id=!',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 10
  },
  'cd-assessment-assigned-queue-not-car': {
    name: 'cd-assessment-assigned-queue-not-car',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=~customer-declaration&data.businessStatus=assessment&data.concept=!car&assignee.id=',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 10
  },
  'cd-assessment-queue-car': {
    name: 'cd-assessment-queue-car',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=~customer-declaration&data.businessStatus=assessment&data.concept=car&assignee.id=!',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 10
  },
  'cd-assessment-assigned-queue-car': {
    name: 'cd-assessment-assigned-queue-car',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=~customer-declaration&data.businessStatus=assessment&data.concept=car&assignee.id=',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 10
  },
  /*
      Added countTaskFilter due to query on flows with this taskFilter is not working.. but this taskFilter on task query does.
    */
  'kyc-failed-not-car': {
    name: 'kyc-failed-not-car',
    queueFilter:
      '?view=queueView&status=!archived&flowDefinitionId[]=customer-declaration-private&flowDefinitionId[]=customer-declaration-business&flowDefinitionId[]=source-of-funds-private&flowDefinitionId[]=source-of-funds-business&data.concept=!car',
    taskFilter:
      '/?status=failed&processDefinitionId[]=customer-declaration-private&processDefinitionId[]=customer-declaration-business&processDefinitionId[]=source-of-funds-private&processDefinitionId[]=source-of-funds-business',
    countTaskFilter: '/?status=failed',
    queueType: 'task'
  },
  'kyc-failed-car': {
    name: 'kyc-failed-car',
    queueFilter:
      '?view=queueView&status=!archived&flowDefinitionId[]=customer-declaration-private&flowDefinitionId[]=customer-declaration-business&flowDefinitionId[]=source-of-funds-private&flowDefinitionId[]=source-of-funds-business&data.concept=car',
    taskFilter:
      '/?status=failed&processDefinitionId[]=customer-declaration-private&processDefinitionId[]=customer-declaration-business&processDefinitionId[]=source-of-funds-private&processDefinitionId[]=source-of-funds-business',
    countTaskFilter: '/?status=failed',
    queueType: 'task'
  }
}
