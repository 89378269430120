export const queueDefinition: any = {
  'my-cases': {
    name: 'my-cases',
    queueType: 'assignee',
    queueFilter: '?status=!completed&status=!archived',
    queueSort: 'sort=createdAt&dir=1',
    limit: 30,
    pollingEnabled: false
  },
  'document-control-completed-unread-chat': {
    name: 'document-control-completed-unread-chat',
    queueFilter: '?flowDefinitionId=document-control-se&status=completed',
    queueSort: 'sort=createdAt&dir=-1',
    queueType: 'chat',
    limit: 30,
    pollingEnabled: false
  },
  Incoming: {
    name: 'Incoming',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control-se&data.externalRequiredDocumentsOk=false',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 30,
    limitIncrease: 20,
    pollingEnabled: false
  },
  Inbox: {
    name: 'Inbox',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control-se&data.externalRequiredDocumentsOk=true&data.allDocumentsAreOk=false&assignee.id=!',
    queueSort: 'sort=data.inboxDate&dir=1',
    limit: 60,
    limitIncrease: 20,
    pollingEnabled: false
  },
  Control: {
    name: 'Control',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control-se&data.externalRequiredDocumentsOk=true&data.allDocumentsAreOk=false&assignee.id=',
    queueSort: 'sort=data.inboxDate&dir=-1',
    limit: 30,
    limitIncrease: 20,
    pollingEnabled: false
  },
  Audit: {
    name: 'Audit',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control-se&sort=createdAt&dir=-1',
    taskFilter:
      '/?taskType=dcse-audit-documents&status=pending&sort=createdAt&dir=-1',
    queueType: 'task',
    limit: 30,
    limitIncrease: 20,
    pollingEnabled: false
  },
  'Select post control': {
    name: 'Select post control',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control-se&sort=createdAt&dir=-1',
    taskFilter:
      '/?taskType=dcse-decide-followup-needed&status=pending&sort=createdAt&dir=1',
    queueType: 'task',
    limit: 20,
    pollingEnabled: false
  },
  'Post control': {
    name: 'Post control',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control-se&sort=createdAt&dir=-1',
    taskFilter: '/?taskType=dcse-follow-up&status=pending&sort=createdAt&dir=1',
    queueType: 'task',
    limit: 20,
    pollingEnabled: false
  },
  Archiving: {
    name: 'Archiving',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=document-control-se&data.storedFiles=',
    queueSort: 'sort=updatedAt&dir=1',
    limit: 20,
    limitIncrease: 20,
    pollingEnabled: false
  },
  Failed: {
    name: 'Failed',
    queueFilter:
      '?view=queueView&flowDefinitionId=document-control-se&status=active&hasErrors=true',
    queueSort: 'sort=createdAt&dir=-1',
    pollingEnabled: false
  },
  'document-control-unread-chat': {
    name: 'document-control-unread-chat',
    queueFilter:
      '?view=queueView&flowDefinitionId=document-control-se&status=active',
    queueSort: 'sort=createdAt&dir=-1',
    queueType: 'chat',
    limit: 30,
    pollingEnabled: false
  },
  'register-se-hp': {
    name: 'register-se-hp',
    queueFilter:
      '?view=queueView&status=!archived&flowDefinitionId=document-control-se&data.product.ncvsId=545&sort=createdAt&dir=-1',
    queueType: 'task',
    taskFilter:
      '/?taskType=dcse-contract-and-car-registry-check&status=pending&sort=createdAt&dir=-1',
    limit: 30,
    limitIncrease: 20,
    pollingEnabled: false
  },
  'perform-payout-se-hp': {
    name: 'perform-payout-se-hp',
    queueFilter:
      '?view=queueView&status=!archived&flowDefinitionId=document-control-se&data.product.ncvsId=545&assignee.id=!&sort=createdAt&dir=-1',
    queueType: 'task',
    taskFilter:
      '/?taskType=dcse-perform-payout-and-start-contract&status=pending&sort=createdAt&dir=-1',
    pollingEnabled: false
  },
  'picked-perform-payout-se-hp': {
    name: 'picked-perform-payout-se-hp',
    queueFilter:
      '?view=queueView&status=!archived&status=!completed&flowDefinitionId=document-control-se&data.product.ncvsId=545&sort=createdAt&dir=-1',
    queueType: 'assignee',
    taskFilter:
      '/?taskType=dcse-perform-payout-and-start-contract&status=pending&sort=createdAt&dir=-1',
    queueSort: 'sort=createdAt&dir=-1',
    pollingEnabled: false
  },
  'register-se-le': {
    name: 'register-se-le',
    queueFilter:
      '?view=queueView&status=!archived&flowDefinitionId=document-control-se&data.product.ncvsId=!545&sort=createdAt&dir=-1',
    queueType: 'task',
    taskFilter:
      '/?taskType=dcse-contract-and-car-registry-check&status=pending&sort=createdAt&dir=-1',
    limit: 30,
    limitIncrease: 20,
    pollingEnabled: false
  },
  'perform-payout-se-le': {
    name: 'perform-payout-se-le',
    queueFilter:
      '?view=queueView&status=!archived&flowDefinitionId=document-control-se&data.product.ncvsId=!545&assignee.id=!&sort=createdAt&dir=-1',
    queueType: 'task',
    taskFilter:
      '/?taskType=dcse-perform-payout-and-start-contract&status=pending&sort=createdAt&dir=-1',
    pollingEnabled: false
  },
  'picked-perform-payout-se-le': {
    name: 'picked-perform-payout-se-le',
    queueFilter:
      '?view=queueView&status=!archived&status=!completed&flowDefinitionId=document-control-se&data.product.ncvsId=!545&sort=createdAt&dir=-1',
    queueType: 'assignee',
    taskFilter:
      '/?taskType=dcse-perform-payout-and-start-contract&status=pending&sort=createdAt&dir=-1',
    queueSort: 'sort=createdAt&dir=-1',
    pollingEnabled: false
  },
  'Credit Inbox': {
    name: 'Credit Inbox',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=credit-check&assignee.id=!&data.caseDetails.contractStatus=!Approved',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 30,
    limitIncrease: 20,
    pollingEnabled: false
  },
  Ongoing: {
    name: 'Ongoing',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=credit-check&assignee.id=&data.caseDetails.contractStatus=!Approved',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 30,
    limitIncrease: 20,
    pollingEnabled: false
  },
  Approved: {
    name: 'Approved',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId=credit-check&data.caseDetails.contractStatus=Approved',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 30,
    pollingEnabled: false
  },
  'Failed credit': {
    name: 'Failed credit',
    queueFilter:
      '?view=queueView&status=!archived&flowDefinitionId=credit-check&hasErrors=true',
    queueSort: 'sort=createdAt&dir=-1',
    pollingEnabled: false
  },
  'credit-unread-chat': {
    name: 'credit-unread-chat',
    queueFilter: '?view=queueView&flowDefinitionId=credit-check&status=active',
    queueSort: 'sort=createdAt&dir=-1',
    queueType: 'chat',
    pollingEnabled: false,
    limit: 30
  },
  'credit-not-approved-uploads': {
    name: 'credit-not-approved-uploads',
    queueFilter: '?flowDefinitionId=credit-check&status=active',
    taskFilter:
      '/?taskType=cc-approve-document&status=pending&sort=createdAt&dir=-1',
    queueType: 'task',
    pollingEnabled: false
  },
  'cd-assessment-queue-not-car': {
    name: 'cd-assessment-queue-not-car',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId[]=customer-declaration-private-se&flowDefinitionId[]=customer-declaration-business-se&data.businessStatus=assessment&data.concept=!car&assignee.id=!',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 10,
    pollingEnabled: false
  },
  'cd-assessment-queue-car': {
    name: 'cd-assessment-queue-car',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId[]=customer-declaration-private-se&flowDefinitionId[]=customer-declaration-business-se&data.businessStatus=assessment&data.concept=car&assignee.id=!',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 10,
    pollingEnabled: false
  },
  'cd-assessment-assigned-queue-car': {
    name: 'cd-assessment-assigned-queue-car',
    queueFilter:
      '?view=queueView&status=!completed&status=!archived&flowDefinitionId[]=customer-declaration-private-se&flowDefinitionId[]=customer-declaration-business-se&data.businessStatus=assessment&data.concept=car&assignee.id=',
    queueSort: 'sort=createdAt&dir=-1',
    limit: 10,
    pollingEnabled: false
  },
  'kyc-failed-car': {
    name: 'kyc-failed-car',
    queueFilter:
      '?view=queueView&status=!archived&flowDefinitionId[]=customer-declaration-private-se&flowDefinitionId[]=customer-declaration-business-se&flowDefinitionId[]=source-of-funds-private&flowDefinitionId[]=source-of-funds-business&data.concept=car',
    taskFilter:
      '/?status=failed&processDefinitionId[]=customer-declaration-private-se&processDefinitionId[]=customer-declaration-business-se&processDefinitionId[]=source-of-funds-private&processDefinitionId[]=source-of-funds-business&sort=createdAt&dir=-1',
    countTaskFilter: '/?status=failed',
    queueType: 'task',
    pollingEnabled: false
  }
}
